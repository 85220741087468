<template>
  <div class="main__inner">
    <div class="success-block success-block--center">
      <div class="success-block__icon">
        <svg
          class="success-block__svg"
          width="79"
          height="79"
          viewBox="0 0 79 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="39.5"
            cy="39.5"
            r="38"
            fill="white"
            stroke="#1C2021"
            stroke-width="3"
          />
          <path
            d="M26 40.8952L35.2548 50L54 30"
            stroke="#2ba527"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="26"
              y1="30"
              x2="44.9189"
              y2="56.4865"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#45AE00" />
              <stop offset="1" stop-color="#08944A" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="success-block__title">
        <h2>Бюллетень отправлен</h2>
      </div>
      <div class="success-block__subtitle">
        <p>
          Вы успешно отправили бюллетень.
          <span v-if="!isFinalStage"
            ><br>Можете пройти демо-голосование еще раз.</span
          >
        </p>
      </div>
      <div class="success-block__buttons">
        <router-link
          class="btn btn--green btn--full btn--100"
          to="/"
          >Далее</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { isFinalStage: false };
  },
};
</script>